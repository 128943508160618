// format:
//       "O" - return params as JS object
//       "J" - return JSON.stringify(params)

export function getDefaultParamsJSON(format = "J") {

  const params = {
    positive: {
      weight: 1,
      words: [
        { w: 'brilliant', wt: 2, wc: 1 },
        { w: 'beautiful', wt: 1, wc: 1 },
        { w: 'certainly', wt: 2, wc: 1 },
        { w: 'completely', wt: 1, wc: 1 },
        { w: 'customer', wt: 2, wc: 1 },
        { w: 'definitely', wt: 1, wc: 1 },
        { w: 'enjoy', wt: 5, wc: 1 },
        { w: 'enjoyable', wt: 5, wc: 1 },
        { w: 'exactly', wt: 1, wc: 1 },
        { w: 'excellent', wt: 5, wc: 1 },
        { w: 'exciting', wt: 2, wc: 1 },
        { w: 'fantastic', wt: 2, wc: 1 },
        { w: 'fascinating', wt: 2, wc: 1 },
        { w: 'favorite', wt: 2, wc: 1 },
        { w: 'friendly', wt: 2, wc: 1 },
        { w: 'generous', wt: 5, wc: 1 },
        { w: 'good', wt: 5, wc: 1 },
        { w: 'great', wt: 5, wc: 1 },
        { w: 'ideal', wt: 2, wc: 1 },
        { w: 'impressive', wt: 2, wc: 1 },
        { w: 'interesting', wt: 2, wc: 1 },
        { w: 'kindly', wt: 2, wc: 1 },
        { w: 'marvellous', wt: 5, wc: 1 },
        { w: 'please', wt: 4, wc: 1 },
        { w: 'quickly', wt: 4, wc: 1 },
        { w: 'recommend', wt: 2, wc: 1 },
        { w: 'serve', wt: 2, wc: 1 },
        { w: 'serving', wt: 2, wc: 1 },
        { w: 'splendid', wt: 1, wc: 1 },
        { w: 'terrific', wt: 2, wc: 1 },
        { w: 'thank you', wt: 2, wc: 2 },
        { w: 'trust', wt: 1, wc: 1 },
        { w: 'trusting', wt: 2, wc: 1 },
        { w: 'welcome', wt: 2, wc: 1 },
        { w: 'wonderful', wt: 2, wc: 1 },
        { w: 'you are welcome', wt: 5, wc: 3 },
        { w: 'grocery store', wt: 5, wc: 2 },
        // { w: 'you're welcome', wt: 5, wc: 2 },
      ]
    },
    negative: {
      weight: 1,
      words: [
        { w: 'cost', wt: 2, wc: 1 },
        { w: 'price', wt: 2, wc: 1 },
        { w: 'costs', wt: 2, wc: 1 },
        { w: 'leakage', wt: 2, wc: 1 },
        { w: 'charge', wt: 2, wc: 1 },
        { w: 'analannie', wt: 2, wc: 1 },
        { w: 'analprobe', wt: 2, wc: 1 },
        { w: 'analsex', wt: 2, wc: 1 },
        { w: 'anus', wt: 2, wc: 1 },
        { w: 'apeshit', wt: 2, wc: 1 },
        { w: 'ass', wt: 2, wc: 1 },
        { w: 'asshole', wt: 7, wc: 1 },
        { w: 'assholes', wt: 2, wc: 1 },
        { w: 'bang', wt: 2, wc: 1 },
        { w: 'bastard', wt: 2, wc: 1 },
        { w: 'bastards', wt: 2, wc: 1 },
        { w: 'beaner', wt: 2, wc: 1 },
        { w: 'beaners', wt: 2, wc: 1 },
        { w: 'bigtits', wt: 2, wc: 1 },
        { w: 'bimbo', wt: 2, wc: 1 },
        { w: 'bimbos', wt: 2, wc: 1 },
        { w: 'bitch', wt: 2, wc: 1 },
        { w: 'bitchass', wt: 2, wc: 1 },
        { w: 'bitched', wt: 2, wc: 1 },
        { w: 'blow', wt: 2, wc: 1 },
        { w: 'blow job', wt: 2, wc: 2 },
        { w: 'blow me', wt: 2, wc: 2 },
        { w: 'blowjob', wt: 2, wc: 1 },
        { w: 'blowjobs', wt: 2, wc: 1 },
        { w: 'boner', wt: 2, wc: 1 },
        { w: 'boners', wt: 2, wc: 1 },
        { w: 'bong', wt: 2, wc: 1 },
        { w: 'boob', wt: 2, wc: 1 },
        { w: 'boobies', wt: 2, wc: 1 },
        { w: 'boobs', wt: 2, wc: 1 },
        { w: 'booby', wt: 2, wc: 1 },
        { w: 'bootee', wt: 2, wc: 1 },
        { w: 'bootie', wt: 2, wc: 1 },
        { w: 'booty', wt: 2, wc: 1 },
        { w: 'booty call', wt: 2, wc: 2 },
        { w: 'booze', wt: 2, wc: 1 },
        { w: 'bull shit', wt: 2, wc: 2 },
        { w: 'bull crap', wt: 2, wc: 2 },
        { w: 'bumblefuck', wt: 2, wc: 1 },
        { w: 'butt fuck', wt: 2, wc: 2 },
        { w: 'butt hole', wt: 2, wc: 2 },
        { w: 'butt plug', wt: 2, wc: 2 },
        { w: 'circlejerk', wt: 2, wc: 1 },
        { w: 'clusterfuck', wt: 2, wc: 1 },
        { w: 'cock', wt: 2, wc: 1 },
        { w: 'cock sucker', wt: 20, wc: 2 },
        { w: 'cocks', wt: 2, wc: 1 },
        { w: 'cock shit', wt: 2, wc: 2 },
        { w: 'crappy', wt: 10, wc: 1 },
        { w: 'cunt', wt: 2, wc: 1 },
        { w: 'cunts', wt: 2, wc: 1 },
        { w: 'cyber fuck', wt: 2, wc: 2 },
        { w: 'cyber fucked', wt: 2, wc: 2 },
        { w: 'cyber fucker', wt: 2, wc: 2 },
        { w: 'cyber sex', wt: 2, wc: 2 },
        { w: 'damn', wt: 2, wc: 1 },
        { w: 'damned', wt: 2, wc: 1 },
        { w: 'damnit', wt: 2, wc: 1 },
        { w: 'doggie style', wt: 2, wc: 2 },
        { w: 'dick head', wt: 2, wc: 2 },
        { w: 'dick face', wt: 2, wc: 2 },
        { w: 'dip shit', wt: 2, wc: 2 },
        { w: 'douche bag', wt: 2, wc: 2 },
        { w: 'dumb ass', wt: 2, wc: 2 },
        { w: 'dumb fuck', wt: 2, wc: 2 },
        { w: 'dumb shit', wt: 2, wc: 2 },
        { w: 'fag', wt: 2, wc: 1 },
        { w: 'faggot', wt: 2, wc: 1 },
        { w: 'fat ass', wt: 2, wc: 2 },
        { w: 'fat fuck', wt: 2, wc: 2 },
        { w: 'finger fuck', wt: 2, wc: 2 },
        { w: 'fuck off', wt: 2, wc: 2 },
        { w: 'gender bender', wt: 2, wc: 2 },
        { w: 'god damn', wt: 2, wc: 2 },
        { w: 'goddam', wt: 2, wc: 1 },
        { w: 'holy shit', wt: 2, wc: 2 },
        { w: 'hussy', wt: 2, wc: 1 },
        { w: 'jerk off', wt: 2, wc: 2 },
        { w: 'masturbate', wt: 2, wc: 1 },
        { w: 'masturbating', wt: 2, wc: 1 },
        { w: 'nimrod', wt: 2, wc: 1 },
        { w: 'octopussy', wt: 2, wc: 1 },
        { w: 'orgasm', wt: 2, wc: 1 },
        { w: 'pecker', wt: 2, wc: 1 },
        { w: 'pecker head', wt: 2, wc: 2 },
        { w: 'piece of shit', wt: 2, wc: 3 },
        { w: 'piss', wt: 2, wc: 1 },
        { w: 'piss off', wt: 2, wc: 2 },
        { w: 'pissed', wt: 10, wc: 1 },
        { w: 'pissed off', wt: 2, wc: 2 },
        { w: 'prick', wt: 2, wc: 1 },
        { w: 'pricks', wt: 2, wc: 1 },
        { w: 'prostitute', wt: 2, wc: 1 },
        { w: 'pubic', wt: 2, wc: 1 },
        { w: 'punkass', wt: 2, wc: 1 },
        { w: 'pussy', wt: 2, wc: 1 },
        { w: 'queer', wt: 2, wc: 1 },
        { w: 'queer bait', wt: 2, wc: 2 },
        { w: 'rape', wt: 2, wc: 1 },
        { w: 'raped', wt: 2, wc: 1 },
        { w: 'rapist', wt: 2, wc: 1 },
        { w: 'rectum', wt: 2, wc: 1 },
        { w: 'retarded', wt: 2, wc: 1 },
        { w: 'rubbish', wt: 2, wc: 1 },
        { w: 'sadism', wt: 2, wc: 1 },
        { w: 'sadist', wt: 2, wc: 1 },
        { w: 'screw', wt: 2, wc: 1 },
        { w: 'screwed', wt: 2, wc: 1 },
        { w: 'scum', wt: 2, wc: 1 },
        { w: 'shit ass', wt: 2, wc: 2 },
        { w: 'shit bag', wt: 2, wc: 2 },
        { w: 'shit fucker', wt: 2, wc: 2 },
        { w: 'shit for brains', wt: 2, wc: 3 },
        { w: 'sleaze', wt: 10, wc: 1 },
        { w: 'sleazy', wt: 2, wc: 1 },
        { w: 'slope', wt: 2, wc: 1 },
        { w: 'slut', wt: 2, wc: 1 },
        { w: 'slut bucket', wt: 2, wc: 2 },
        { w: 'smart ass', wt: 2, wc: 2 },
        { w: 'smart asses', wt: 2, wc: 2 },
        { w: 'sob', wt: 2, wc: 1 },
        { w: 'son of a bitch', wt: 2, wc: 4 },
        { w: 'stupid', wt: 10, wc: 1 },
        { w: 'suck', wt: 2, wc: 1 },
        { w: 'suck ass', wt: 2, wc: 2 },
        { w: 'sucked', wt: 2, wc: 1 },
        { w: 'sucking', wt: 2, wc: 1 },
        { w: 'sucks', wt: 2, wc: 1 },
        { w: 'titties', wt: 2, wc: 1 },
        { w: 'titty', wt: 2, wc: 1 },
        { w: 'turd', wt: 2, wc: 1 },
        { w: 'weenie', wt: 2, wc: 1 },
        { w: 'whore', wt: 2, wc: 1 },
        { w: 'whore bag', wt: 2, wc: 2 },
        { w: 'whored', wt: 2, wc: 1 },
        { w: 'whore face', wt: 2, wc: 2 },
        { w: 'whore house', wt: 2, wc: 2 },
        { w: 'whores', wt: 2, wc: 1 },
      ]
    },
    required: {
      weight: 1,
      words: [
        { w: 'and', wt: 12, wc: 1 },
        { w: 'discrete', wt: 2, wc: 1 },
        { w: 'time', wt: 2, wc: 1 },
        { w: 'wipes', wt: 2, wc: 1 },
        { w: 'freedom', wt: 2, wc: 1 },
        { w: 'leakage', wt: 2, wc: 1 },
        { w: 'how are you today', wt: 5, wc: 4 },
        { w: 'thank you for trusting', wt: 5, wc: 4 },
        { w: 'my name is', wt: 5, wc: 3 },
        { w: 'estimated charges', wt: 3, wc: 2 },
        { w: 'visa mastercard or american express', wt: 10, wc: 5 },
        { w: 'payment plan', wt: 5, wc: 2 },
        { w: 'charity care', wt: 2, wc: 2 },
      ]
    }
  }
  if (format === "J") {
    return JSON.stringify(params)
  }
  return params
}
